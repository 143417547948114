import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useTaxs() {
    const router = useRouter();
  const getTax_Wait = async (page) => {
    return await ApiService.query("tax_wait",page);
  };

  const getTax_Processing = async (page) => {
    return await ApiService.query("tax_processing",page);
  };

  const getTax_Finish = async (page) => {
    return await ApiService.query("tax_finish",page);
  };

  const getTax = async (id) => {
    return await ApiService.get("taxs/" + id);
  };

  const getAticket = async (data) => {
    await ApiService.put("get_tax_a_ticket/" + data.id, data);
    await router.push({
      name: "apps.taxs.taxs.show",
      params: { id: data.id },
    });
  };

  const FinishAticket = async (data) => {
    await ApiService.put("finish_tax_a_ticket/" + data.id, data);
    await router.push({
      name: "apps.taxs.taxs.show",
      params: { id: data.id },
    });
  };

  const getNotifyToken = async () => {
    return await ApiService.get("tax_notify_token");
  };


  const updateNotifyToken = async (data) => {
    await ApiService.put("update_tax_notify_token", data);
    await router.push({
      name: "apps.taxs.notify.index"
    });
  };



  


  return {
    getTax,
    getTax_Wait,
    getTax_Processing,
    getTax_Finish,
    getAticket,
    FinishAticket,
    getNotifyToken,
    updateNotifyToken
  };
}
