<template>
  <v-pagination
    style="float: right"
    v-model="pag.current_page"
    :pages="pag.last_page"
    :range-size="1"
    active-color="#009EF7"
    @update:modelValue="updateHandler"
  />
</template>

<script>
import { computed, defineComponent } from "vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default defineComponent({
  name: "Pagination",
  components: {
    VPagination,
  },
  props: ["meta"],
  setup(props, { emit }) {
    const pag = computed(() => {
      return props.meta;
    });
    function updateHandler(e) {
      emit("pagination", e);
    }

    return { updateHandler, pag };
  },
});
</script>
